import React from 'react';

const ActiveAscending = props => (
  <svg viewBox="0 0 10 16" fill="none" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M0 5l5-5 5 5H0z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M0 11l5 5 5-5H0z" fill="currentColor" fillOpacity=".1" />
  </svg>
);

export default ActiveAscending;
