import 'flexboxgrid';
import './index.scss';
import React from 'react';
import { render } from 'react-dom';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import { ApolloClient, InMemoryCache, HttpLink, ApolloProvider, gql } from '@apollo/client';
import { resolvers, typeDefs } from './resolvers';
import { getCookie, getAuthUser } from './utils';

const cache = new InMemoryCache();
const client = new ApolloClient({
  link: new HttpLink({
    uri: process.env.REACT_APP_GRAPHQL_URL,
    credentials: 'include'
  }),
  cache,
  typeDefs,
  resolvers
});

function writeInitialData() {
  cache.writeQuery({
    query: gql`
      query {
        authUser @client,
        searchTerm @client
      }
    `,
    data: {
      authUser: getCookie('86553b39') && (getCookie('86553b39') !== 'undefined') ? getAuthUser() : null,
      searchTerm: ''
    }
  });
}

writeInitialData();

function Root() {
  return (
    <ApolloProvider client={client}>
      <Router>
        <App />
      </Router>
    </ApolloProvider>
  );
}

render(<Root />, document.getElementById('root'));


if (module.hot && process.env.NODE_ENV !== 'production') {
  module.hot.accept();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
