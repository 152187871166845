import './VIPSListActionBar.scss';
import React from 'react';
import { Button, Switch } from 'react-md';
import { isNotEmpty } from '../../utils';
import * as routes from '../../constants/routes';

const VIPSListActionBar = ({ selected, vips, handleSwitch, toggled, history, setSelectedVips }) => {
  const selectAll = () => {
    setSelectedVips(vips.map(vip => vip.id));
  };

  const deselectAll = () => {
    setSelectedVips([]);
  };

  return (
    <div className="vips-list-actionbar">
      <div className="filter-chip-dropdown">
        <Switch
          checked={toggled}
          name="duplicate-filter"
          id="switch-duplicate"
          className="duplicate-switch"
          type="switch"
          onChange={handleSwitch}
          label={toggled ? 'Only show suspected duplicates' : 'Filter suspected duplicates'}
          labelClassName={toggled ? 'toggled' : 'untoggled'}
        />
      </div>
      <div className="action-container">
        {
          !isNotEmpty(vips) ? null : selected.length === vips.length ?
            <Button className="button ghost" onClick={deselectAll} flat>Deselect All</Button> :
            <Button className="button ghost" onClick={selectAll} flat>Select All</Button>
        }
        <Button
          flat
          className="button ghost"
          onClick={() => history.push(routes.CREATE_VIPS)}
        >
          create new vip
        </Button>
      </div>
    </div>
  );
};

export default VIPSListActionBar;
