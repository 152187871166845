import { create } from 'axios';

const ajax = create({
  timeout: 100000
});

export const postCSV = (file, lots, events) => {
  const formdata = new FormData();

  formdata.append('file', file, file.name);
  lots.forEach((lot) => formdata.append('lotIds', lot));
  events.forEach((event) => formdata.append('eventIds', event));

  return ajax.post(`${process.env.REACT_APP_CSV_URL}`, formdata );
};
