import './CSVResponseDialog.scss';
import React from 'react';
import { DialogContainer, Button } from 'react-md';

const CSVResponseDialog = (props) => {
  const { visible, onHide, data } = props;

  const error = data && !data.setError ? (
    <div className="error-body">
      <h3>There were errors found on the following rows:</h3>
      <ul className="errors">
        {data.map((err, ind) => {
          return (<li key={ind}>{`Row ${err.line} ${err.error}`}</li>);
        })}
      </ul>
      <p>Please correct the file and re-upload</p>
    </div>
  ) :
    <h3>{data.setError}</h3>;

  return (
    <DialogContainer
      id="csv-dialog"
      visible={visible}
      onHide={onHide}
      aria-describedby="CSV upload response"
      modal
      title="CSV Parse error"
    >
      {error}
      <Button
        className="dismiss-error"
        flat
        primary
        swapTheming
        onClick={onHide}
      >
        OK
      </Button>
    </DialogContainer>
  );
};

export default CSVResponseDialog;
