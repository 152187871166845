import React, { useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import TableIllustration from './table-illustration.png';

const activeStyle = {
  borderColor: '#2196F3',
  backgroundColor: 'rgba(33, 150, 243, .3)'
};

const acceptStyle = {
  borderColor: '#2196F3'
};

const rejectStyle = {
  borderColor: '#ff1744',
  backgroundColor: 'rgba(255, 23, 68, .3)'
};

function StyledDropzone(props) {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    onDrop: props.onDrop,
    accept: 'text/csv, application/vnd.ms-excel, '
  });

  const style = useMemo(() => ({
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [isDragAccept, isDragActive, isDragReject]);

  return (
    <div className="dropzone" {...getRootProps({ style })}>
      <input disabled={props.posting} {...getInputProps()} />
      <h2>Drag .CSV file here</h2>
      <p>
        or click this window to
        <br />
        open your file browser
      </p>
      <img src={TableIllustration} alt="template" />
      <div className="dropzone-message-container">
        {isDragReject &&
          <span className="dropzone-error">File type not accepted</span>
        }
      </div>
    </div>
  );
}

export default StyledDropzone;
