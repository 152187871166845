import React from 'react';
import { ActiveAscending, ActiveDescending, InactiveSort } from '../../assets/react-icons';

const ThItem = (props) => {
  const { children, activeSortKey, sortKey, direction, onClick, sortable } = props;

  function handleClick() {
    onClick(sortKey, sortable);
  }
    
  return (
    <div className={sortKey ? `th-item ${sortKey.toLowerCase()}` : ''} onClick={handleClick}>
      <span>{children}</span>
      <span className="th-icon">
        {
          (activeSortKey === sortKey) ?
            direction === 'asc' ?
              <ActiveAscending />
              :
              <ActiveDescending />
            : sortKey && <InactiveSort />
        }
      </span>
    </div>
  );
};

export default ThItem;
