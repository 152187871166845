import './TopActionBar.scss';
import React from 'react';
import { Button } from 'react-md';
import { SearchInput } from '../index';
import { setItem } from '../../utils/sessionStorage';
import { gql, useQuery } from '@apollo/client';

const GET_SEARCH_TERM = gql`
  {
    searchTerm @client
  }
`;

const TopActionBar = ({ events, selected, setView, listView, update, children }) => {
  const { loading, data, client } = useQuery(GET_SEARCH_TERM);

  function selectAll() {
    setItem('selectedEvents', events.map(e => e.id));
    update();
  }

  function deselectAll() {
    setItem('selectedEvents', []);
    update();
  }

  function updateSearchTerm(input) {
    client.writeQuery({
      query: GET_SEARCH_TERM,
      data: {
        searchTerm: input
      }
    });
  }

  return (
    <div className="top-action-bar row">
      <div className="col-xs-12 col-sm-6">
        <div className="search-filter">
          {!loading &&
            <SearchInput
              setSearchTerm={updateSearchTerm}
              searchTerm={data.searchTerm}
            />
          }
          {children}
        </div>
      </div>
      <div className="col-xs-12 col-sm-6">
        <div className="action-container">
          <Button
            className="list-view"
            flat
            iconChildren={listView ? 'view_module' : 'view_list'}
            id="card-view-toggle"
            onClick={setView}
          >
            {listView ? 'Card View' : 'List View'}
          </Button>

          {
            selected.length === events.length ?
              <Button className="button ghost" onClick={deselectAll} flat>Deselect All</Button> :
              <Button className="button ghost" onClick={selectAll} flat>Select All</Button>
          }
        </div>
      </div>
    </div>
  );
};

export default TopActionBar;
