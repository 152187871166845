import './SearchInput.scss';
import React, { Component } from 'react';
import { SearchIcon } from '../../assets/react-icons/';

export class SearchInput extends Component {

  componentDidMount() {
    this.props.setSearchTerm('');
  }

  change = (val) => {
    this.props.setSearchTerm(val);
  };

  render() {
    return (
      <div className="search-input-container" >
        <input type="text" onChange={(e) => this.change(e.target.value)} placeholder="Search by keyword" value={this.props.searchTerm} />
        <span>
          <SearchIcon color="rgba(0,0,0,.6)" width={24} />
        </span>
      </div>
    );
  }
};
