import axios from 'axios';
import jwt from 'jsonwebtoken';
import _ from 'lodash';
import moment from 'moment';
import { Cookies } from 'react-cookie';
import { FUSE_OPTIONS } from './fuseOptions';
import { getItem, removeItem, setItem } from './sessionStorage';

export { FUSE_OPTIONS, getItem, setItem, removeItem };

const cookies = new Cookies();

const ajax = axios.create({
  baseURL: process.env.REACT_APP_CORE_USER_API_URL,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true
});

export const idFormatter = (string) => string.toLowerCase().split(' ').join('-');

// returns object formatted for use with checklists
export function createFilterList(data, keyName, filterValues) {
  // if filterValues is passed in and has a value it will be checked for when creating the list
  const checked = (value) => filterValues && filterValues.length > 0 ? filterValues.includes(value) : true;

  return _.uniqBy(data.map((item) => ({ name: item[keyName], id: item.id, isChecked:  checked(item.id) })), 'name');
}

// check if array or object is empty
export function isNotEmpty(array) {
  return !_.isEmpty(array);
}

// convert milliseconds timestamp to more readable format
export function makeTimeReadable(date) {
  return {
    date: moment.unix(date, 'X').format('MM/DD/YY'),
    time: moment.unix(date, 'X').format('h:mm a')
  };
}

export function dateTimeFormatter(date) {
  return moment(date).format('MM/DD/YY h:mm a');
}

// sort by key
export function orderByKey(list, key, direction = 'asc') {
  return _.orderBy(list, [o => o[key].toLowerCase()], direction);
}

// get value of object by path. Path can be nested. example: resolvePropDepth('name.first', obj)
export function resolvePropDepth(path, obj) {
  return path.split('.').reduce(function (prev, curr) {
    return prev ? prev[curr] : null;
  }, obj || this);
}

// truncate string with max character count
export function truncate(string, max) {
  return string.length > max ? string.substring(0, max) + '...' : string;
}

// get cookie by name
export function getCookie(name) {
  return cookies.get(name);
}

// update auth cookie
export function refreshSession() {
  const value = getCookie('86553b39');

  if (value && value !== 'undefined') {
    return ajax.post('authenticate?refreshCookie=true');

  } else {
    console.error(`Can not refresh session because current cookie expired`);
  }
}

// Sign out user
export function signOut(params = '') {
  return window.location.replace(`${process.env.REACT_APP_SUITE_URL + 'sign-out' + params}`);
}

export function deleteCookies() {
  cookies.remove('86553b39', {
    domain: '.parkhub.com'
  });
  cookies.remove('_gtmUID', {
    domain: '.parkhub.com'
  });
}

function checkForEnvMatch() {
  if (getCookie('86553b39')) {
    return getAuthUser().ne === process.env.REACT_APP_ENV_TAG;
  } else {
    return false;
  }
}

export const checkAuth = () => {
  const authCookie = getCookie('86553b39');

  if (authCookie && authCookie !== 'undefined' && checkForEnvMatch()) {
    return true;

  } else {
    deleteCookies();
    return false;
  }
};

export function getAuthUser() {
  return jwt.decode(getCookie('86553b39'), { complete: true }).payload.payload;
}

export function getSessionTime() {
  const iat = jwt.decode(getCookie('86553b39'), { complete: true }).payload.iat;
  return Math.abs((new Date(iat * 1000) - new Date()) / 1000 / 60);
}
