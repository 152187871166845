import './CreateVIPS.scss';
import React, { useEffect, useState, useRef } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import _ from 'lodash';
import { Button, TextField, List, ListItemControl, Checkbox, DialogContainer } from 'react-md';
import { Footer, SubHeader } from '../../components';
import * as routes from '../../constants/routes';
import { isNotEmpty, getItem } from '../../utils';

const eventsQuery = loader('../../graphql/events.query.graphql');
const createVIPsMutation = loader('../../graphql/createVip.mutation.graphql');

const CreateVip = ({ history, updateToast }) => {
  const [duplicates, setDuplicates] = useState([]);
  const [vipList, setVipsList] = useState([{ first: '', last: '' }]);
  const [visible, setVisible] = useState(false);
  const [selectedLots, setSelectedLots] = useState([]);
  const [inputFocus, setInputFocus] = useState(false);
  const initialInput = useRef(null);

  const { loading, data: eventsData } = useQuery(eventsQuery, {
    variables: {
      eventIDs: getItem('selectedEvents') ? getItem('selectedEvents')[0] : []
    }
  });

  const [createVIPs] = useMutation(createVIPsMutation, {
    onCompleted(data) {
      if (data && data.createVIPs.uniqueRecordCount) {
        const count = data.createVIPs.uniqueRecordCount;

        updateToast([{
          text: `${count} ${count === 1 ? 'VIP' : 'VIPs'} created`,
          action: 'dismiss'
        }]);

        history.push(routes.VIPS, { refetch: true });
      }
    },
    onError(...error) {
      console.log(error);
      updateToast([{
        text: `Error creating VIP. If the problem persists please contact administrator.`,
        action: 'dismiss'
      }]);
    }
  });

  useEffect(() => {
    if (inputFocus) {
      setInputFocus(initialInput);
      setFocus(initialInput);
    }

    if (!getItem('selectedEvents') || !getItem('selectedEvents').length || getItem('selectedEvents').length > 1) {
      history.push(routes.ROOT);
    }
  }, [inputFocus, history]);

  function setFocus(ref) {
    ref.current.focus();
  }

  function addNameField() {
    setInputFocus(true);
    setVipsList([...vipList, { first: '', last: '' }]);
  };

  function removeNameField(index) {
    setVipsList(vipList.filter((vip, idx) => idx !== index));
  };

  function handleChange(val, index, name) {
    vipList[index][name] = val;
    setVipsList([...vipList]);
  }

  function handleSelection(id) {
    if (selectedLots.includes(id)) {
      setSelectedLots((prevSelectedLots) => {
        const newList = [...prevSelectedLots];

        return newList.filter((item) => item !== id);
      });

    } else {
      setSelectedLots((prevSelectedLots) => {
        return [id, ...prevSelectedLots];
      });
    }
  }

  function checkNames() {
    return vipList.filter(vip => {
      return (vip.first.length === 0 || vip.first.length > 50) || (vip.last.length === 0 || vip.last.length > 50);
    }).length === 0;
  }

  function handleCreation() {
    const variables = {
      eventIDs: getItem('selectedEvents'),
      lotIDs: selectedLots,
      vips: vipList.map(vip => {
        return { firstName: vip.first, lastName: vip.last };
      })
    };

    createVIPs({
      variables
    });
  }

  // Compares the old vips against the new vips to be created.
  function duplicateCheck() {
    const oldDuplicates = findOldDuplicates(vipList);
    if (oldDuplicates.length) {
      setDuplicates(oldDuplicates);
      setVisible(true);
    } else {
      handleCreation();
    }
  }

  function findOldDuplicates(list) {
    let newDuplicates = [];
    const currentNames = list.map((newVip => `${newVip.first} ${newVip.last}`.toLowerCase()));
    const compareList = eventsData.event.records[0].vips.map(item => item.fullName.toLowerCase());

    compareList.forEach((name) => {
      currentNames.forEach((currentName) => {
        if (name === currentName) {
          newDuplicates.push(currentName);
        }
      });
    });

    return newDuplicates;
  };

  function hide() {
    setVisible(false);
  };

  function handleNavigation() {
    history.push(routes.VIPS);
  }

  function isDisabled() {
    return !(checkNames() && Boolean(selectedLots && selectedLots.length));
  }

  const createNewFields = (index, vip) => {
    return (
      <div key={index} className="name-fields row">
        <TextField
          ref={initialInput}
          id="first-name"
          label="First"
          className="first col-xs-5 col-md-6"
          maxLength={50}
          errorText="Input cannot exceed 50 characters"
          onChange={(e) => handleChange(e, index, 'first')}
        />
        <TextField
          className="last col-xs-5 col-md-6"
          id="last-name"
          label="Last"
          onChange={(e) => handleChange(e, index, 'last')}
          maxLength={50}
          errorText="Input cannot exceed 50 characters"
          inlineIndicator={index !== 0 ? <Button icon onClick={() => removeNameField(index)}>delete</Button> : <div />}
        />
      </div>
    );
  };

  return (
    <div className="wrapper create-vips">
      <section className="row">
        <div className="col-xs">
          <SubHeader label="Create New VIPs" backAction={() => handleNavigation()} />
        </div>
      </section>
      <section className="row">
        <div className="names col-xs">
          <span className="title-name">Name</span>
          <div>
            {vipList.map((vip, index) => createNewFields(index, vip))}
          </div>
          <br />
          <div className="row end-xs">
            <div className="col-xs">
              <Button
                className="button ghost"
                disabled={!checkNames()}
                onClick={checkNames() ? addNameField : undefined}
                flat
              >
                Add another VIP
              </Button>
            </div>
          </div>
        </div>
      </section>
      <section className="row lots-title">
        <div className="col-xs">
          <span className="title-lots">Lots</span>
          <p className="subtitle-lots">Only lots for this event are shown</p>
        </div>
      </section>
      <section className="row lots">
        <div className="col-xs">
          <List>
            {!loading && isNotEmpty(eventsData.event.records[0]) && eventsData.event.records[0].lots.map((lot, index) => {
              const isSelected = selectedLots && selectedLots.includes(lot.id);
              return (
                <ListItemControl
                  key={index}
                  className={`${isSelected ? 'selected' : ''}`}
                  primaryAction={
                    <Checkbox
                      checked={_.includes(selectedLots, lot.id)}
                      id={`list-control-${index}`}
                      name={`list-control-${index}`}
                      label={lot.name}
                      onChange={() => handleSelection(lot.id)}
                    />
                  }
                />
              );
            })
            }
          </List>
        </div>
      </section>
      <DialogContainer
        id="duplicates-modal"
        className="dialog"
        focusOnMount={false}
        visible={visible}
        stackedActions={false}
        title="Create duplicates?"
        onHide={hide}
        width="510px"
        actions={[
          <Button key="edit" swapTheming primary flat onClick={hide}>Edit List</Button>,
          <Button key="continue" swapTheming secondary onClick={handleCreation} flat>Continue</Button>
        ]}
        modal
      >
        <p>You already have the following entries in your VIP list:</p>
        <ul className="duplicates">
          {duplicates.map((duplicate, index) => {
            return (
              <li key={`${duplicate}-${index}`}>{`${duplicate}`}</li>
            );
          })}
        </ul>
      </DialogContainer>
      <Footer>
        <Button
          disabled={isDisabled()}
          flat
          onClick={() => duplicateCheck()}
          primary
          swapTheming
        >
          {vipList.length > 1 ? 'Create New Vips' : 'Create New Vip'}
        </Button>
      </Footer>
    </div>
  );
};

export default CreateVip;
