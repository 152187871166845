import './EventCard.scss';
import React from 'react';
import { Card, CardText, FontIcon } from 'react-md';
import americanFootballImg from './americanfootball.png';
import otherImg from './other.png';
import concertImg from './concert.png';
import TextTruncate from 'react-text-truncate';
import { getItem } from '../../utils/sessionStorage';
import moment from 'moment';
import { isNotEmpty } from '../../utils';
const images = {
  'tag.event.sporting': americanFootballImg,
  'tag.event.other': otherImg,
  'tag.event.musical': concertImg
};
const EventCard = ({ event, selected, increaseCount, decreaseCount }) => {

  function getImage(tags) {
    const tag = isNotEmpty(tags) && tags.filter(t => {
      const nodes = t.path.split('.');
      return nodes.includes('event') ? true : false;
    });

    if (tag !== '') {
      return ((isNotEmpty(tag) && images[tag[0].path]) || otherImg);
    } else {
      return otherImg;
    }
  }

  function handleClick(e) {
    const selected = getItem('selectedEvents') || [];

    if (!selected.includes(e.id)) {
      increaseCount(e.id);
    } else {
      decreaseCount(e.id);
    }
  }

  return (
    <Card onClick={() => handleClick(event)} className={`event-card ${selected.includes(event.id) ? 'selected' : ''}`}>
      <div className="event-image-container image-background">
        {selected.includes(event.id) ?
          <>
            <FontIcon className="check-button background">brightness_1</FontIcon>
            <FontIcon className="check-button blue">check_circle</FontIcon>
          </>
          :
          <FontIcon className="check-button white">radio_button_unchecked</FontIcon>
        }
        <img src={getImage(event.metatags)} className="image" alt="event" />
      </div>
      <CardText className="event-card-text">
        {event &&
          <div>
            <div className="event-title">
              <h5>
                <TextTruncate line={2} text={event.name} />
              </h5>
            </div>
            <div className="event-details">
              <div>
                <h5>
                  <FontIcon className="green">check_circle</FontIcon>
                  {event.vipCount} VIPs assigned
                </h5>
              </div>
              <div>
                <h5>
                  <FontIcon className="blue">room</FontIcon>
                  {event.landmark.name}
                </h5>
              </div>
              <div>
                <h5>
                  <FontIcon className="blue">event</FontIcon>
                  {moment(event.from, ['YYYY-MM-DD HH:mm:ss ZZ']).format('MM/DD/YY - hh:mm a')}
                </h5>
              </div>
            </div>
          </div>
        }
      </CardText>
    </Card>
  );
};


export default EventCard;
