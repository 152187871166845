import { gql, useQuery } from '@apollo/client';
import { Header, SessionDialog } from '@parkhub/parkhub-ui';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Snackbar } from 'react-md';
import { Route, Switch, useLocation, withRouter } from 'react-router-dom';
import ParkhubLogo from './assets/parkhub-vip-logo-gray.svg';
import * as routes from './constants/routes';
import CreateVIPS from './containers/CreateVIPS/CreateVIPS';
import Csv from './containers/Csv/Csv';
import EditVIP from './containers/EditVIP/EditVIP';
import Events from './containers/Events/Events';
import Lots from './containers/Lots/Lots';
import VIPS from './containers/VIPS/VIPS';
import { checkAuth, getSessionTime, refreshSession, signOut } from './utils';

const GET_AUTH_USER = gql`
  {
    authUser @client
  }
`;

const App = (props) => {
  const [sessionModalVisible, setSessionModalVisible] = useState(false);
  const [toastList, setToastList] = useState([]);
  const checkTime = useRef(false);
  const { data, loading } = useQuery(GET_AUTH_USER);
  const location = useLocation();

  function hide() {
    setSessionModalVisible(false);
  }

  function show() {
    setSessionModalVisible(true);
  }

  function dismissToast() {
    setToastList([]);
  }

  const startTimeout = useCallback(
    () => {
      refreshSession()
        .then(() => {
          clearInterval(checkTime.current);
          const checkSessionTimeout = () => {

            if (!checkAuth()) {
              signOut();
            } else {
              const minutes = getSessionTime();
              if (minutes > 1440) {
                setSessionModalVisible(false);
                signOut('?expired=true');

              } else if (minutes > 1438 && minutes < 1440) {
                show();
              }
            }
          };

          checkTime.current = setInterval(checkSessionTimeout, 10000);
        });
    }, [],
  );

  const checkLoggedIn = useCallback(
    () => {
      if (!checkAuth()) {
        window.location.replace(`${process.env.REACT_APP_SUITE_URL}signin?redirectUrl=${window.location.href}`);
      } else {
        startTimeout();
      }
    }, [startTimeout],
  );

  useEffect(() => {
    checkLoggedIn();

    return () => {
      clearInterval(checkTime.current);
    };
  }, [location, checkLoggedIn]);

  return (
    <>
      {!loading && !!data.authUser &&
        <>
          <Header
            authUser={data.authUser}
            signOut={() => signOut()}
            logoSrc={ParkhubLogo}
            logoWidth={239}
            logoClick={()=> props.history.push(routes.ROOT)}
          />
          <Switch>
            <Route path={routes.VIPS} render={() => <VIPS updateToast={setToastList} {...props} />} />
            <Route path={routes.CREATE_VIPS} render={() => <CreateVIPS updateToast={setToastList} {...props} />} />
            <Route path={routes.EDIT_VIP} render={() => <EditVIP updateToast={setToastList} {...props} />} />
            <Route exact path={routes.ROOT} render={() => <Events {...props} />} />
            <Route path={routes.LOTS} render={() => <Lots {...props} />} />
            <Route path={routes.CSV} render={() => <Csv updateToast={setToastList} {...props} />} />
          </Switch>
          <Snackbar
            id="snackbar"
            toasts={toastList}
            autohide
            autohideTimeout={5000}
            onDismiss={dismissToast}
          />
          <SessionDialog
            onHide={hide}
            visible={sessionModalVisible}
            onContinue={startTimeout}
            onTimeExpire={() => signOut('?expired=true')}
            onSignOut={signOut}
          />
        </>
      }
    </>
  );
};


export default withRouter(App);
